export default {
  confirm: "Confirm",
  cancel: "Cancel",
  close: "Close",
  add: "Add",
  save: "Save",
  remove: "Remove",
  delete: "Delete",
  open: "Open",
  edit: "Edit",
  duplicate: "Duplicate",
  activate: "Activate",
  deactivate: "Deactivate",
  none: "None",
  logged_in_user: "Logged-in user",
  passwordRule: "Password has to be at least 6 characters long <br/> and contain a letter, a number and a symbol(!@#$%^&*?-=+_)",
  customer: {
    customer: "Customer",
    recipient: "Recipient",
    select_subject: "Select customer",
    select_recipient: "Select recipient",
    name: "Name",
    address: "Address",
    email: "E-mail",
    phone: "Phone number",
    user: {
      no_results_message: "This User does not exist",
      no_results_button: "Create new user"
    },
    subject_no_result_data: {
      no_results_message: "This Customer does not exist",
      no_results_button: "Create new Customer"
    },
    add_customer: "Add new customer"
  },
  colors: {
    red: "Red",
    orange: "Orange",
    yellow: "Yellow",
    lime: "Lime",
    green: "Green",
    cyan: "Cyan",
    blue: "Blue",
    purple: "Purple",
    pink: "Pink",
    gray: "Gray"
  },
  menu: {
    views: {
      tenants: "Licenses"
    }
  },
  components: {
    creation_info: {
      creation_info: "Creation Info",
      created_at: "Date created",
      created_by: "User author",
      updated_at: "Date modified",
      updated_by: "User last modified"
    },
    contact_info: {
      address: {
        title: "Address",
        remove_button: "Remove address",
        add_button: "Add address",
        address: "Address",
        postal: "Postal",
        city: "City",
        country: "Country"
      },
      contact: {
        title: "Contact",
        remove_button: "Remove contact",
        add_button: "Add contact",
        contact: "Contact",
        phone: "Phone number",
        email: "E-mail",
        any: "Contact",
        type: "Type",
        label: "Additional label"
      }
    },
    multiple_entries_check: {
      label: "Wish to add another?",
      description: "Form will stay open after confirmation"
    },
    recurrence: {
      form: {
        type: "Interval",
        types: {
          hourly: "Hourly",
          daily: "Daily",
          weekly: "Weekly",
          monthly: "Monthly",
          yearly: "Yearly"
        },
        recurrence_headline: "Recurrence {number}",
        remove_button: "Remove recurrence",
        add_button: "Add recurrence",
        time: "Time",
        minutes: "Minutes",
        weekday: "Day",
        weekdays: {
          monday: "Monday",
          tuesday: "Tuesday",
          wednesday: "Wednesday",
          thursday: "Thursday",
          friday: "Friday",
          saturday: "Saturday",
          sunday: "Sunday"
        },
        day: "Day",
        first_day_of_month: "First day of the month",
        last_day_of_month: "Last day of the month",
        month: "Month",
        months: {
          january: "January",
          february: "February",
          march: "March",
          april: "April",
          may: "May",
          june: "June",
          july: "July",
          august: "August",
          september: "September",
          october: "October",
          november: "November",
          december: "December"
        }
      }
    },
    query_builder: {
      title: "Find {models} where ...",
      type: {
        string: "Text",
        number: "Number",
        boolean: "Boolean",
        date: "Date",
        enum: "Enum",
        relation: "Relation"
      },
      comparison_operators: {
        string: {
          "=": "Is",
          "!=": "Is not",
          contains: "Contains",
          starts_with: "Starts with",
          ends_with: "Ends with",
          is_empty: "Is empty",
          is_not_empty: "Is not empty"
        },
        number: {
          "=": "=",
          "!=": "≠",
          "<": "<",
          ">": ">",
          "<=": "≤",
          ">=": "≥",
          exists: "Exists",
          not_exists: "Doesn't exist"
        },
        boolean: {
          "=": "Is",
          "!=": "Is not"
        },
        date: {
          "=": "Is",
          "!=": "Is not",
          "<": "Is before",
          ">": "Is after",
          "<=": "Is on or before",
          ">=": "Is on or after",
          exists: "Exists",
          not_exists: "Doesn't exist"
        },
        enum: {
          "=": "Is",
          "!=": "Is not",
          is_empty: "Is empty",
          is_not_empty: "Is not empty"
        },
        relation_one: {
          exists: "Exists",
          not_exists: "Doesn't exist",
          exists_where: "Exists where...",
          not_exists_where: "Doesn't exist where..."
        },
        relation_many: {
          exists: "Exist",
          not_exists: "Don't exist",
          exists_where: "Exist where...",
          not_exists_where: "Don't exist where..."
        }
      },
      logical_operators: {
        and: "And",
        or: "Or"
      },
      property_placeholder: "Select a property",
      boolean: {
        true: "Yes",
        false: "No"
      },
      date: {
        type: {
          today: "Today",
          tomorrow: "Tomorrow",
          yesterday: "Yesterday",
          week_ago: "One week ago",
          week_from_now: "One week from now",
          month_ago: "One month ago",
          month_from_now: "One month from now",
          now: "Now",
          hour_ago: "One hour ago",
          hour_from_now: "One month from now",
          "12h_ago": "12 hours ago",
          "12h_from_now": "12 hours from now",
          this_year: "This year",
          year_ago: "One year ago",
          year_from_now: "One year from now",
          date: "Exact date",
          datetime: "Exact date and time",
          time: "Exact time",
          month: "Exact month",
          year: "Exact year"
        }
      },
      relation: {
        results_count: "Number of results found"
      },
      add_filter_button: "Add a filter",
      add_filter_group_button: "Add a filter group",
      actions: {
        duplicate: "Duplicate",
        turn_into_group: "Turn into group",
        release_group: "Release group",
        remove: "Remove"
      }
    },
    pantheon_widget: {
      title: "Pantheon",
      saved: {
        title: "Saved in Pantheon",
        description: "{title} has been successfully saved in Pantheon on {date}, at {time}.\n",
        button: "Save now"
      },
      not_saved: {
        title: "Not saved in Pantheon",
        description: "{title} has not yet been saved in Pantheon.",
        button: "Save in Pantheon"
      },
      error: {
        title: "Pantheon sync error",
        description: "Pantheon sync has encountered an error. This could be due to Pantheon being unavailable at the time of syncing.\nLast successfull save happened on {date}, at {time}.\nTry saving again. If the issue isn't resolved, contact the administrator.",
        button: "Save in Pantheon"
      }
    },
    query_builder_form_section: {
      title: "Filter",
      description: "Use filters to narrow the results.",
      add_button: "Set filters",
      edit_button: "Edit filters",
      remove_button: "Remove filters",
      popup_title: "Find items where ..."
    }
  },
  trial_bar_messages: {
    expire: "Your licence will expire in",
    day: "day",
    days: "days",
    redirect: "You can extend your licence by following the link.",
    contact_admin: "Contact administrator",
    extend_licence: "to extend your licence."
  },
  top_bar_with_url: {
    click_here: "Click here"
  }
}
