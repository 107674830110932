export default {
  role: {
    views: {
      list: {
        header: {
          title: "Roles",
          search_placeholder: "Search ...",
          add_button: "Add role"
        },
        table: {
          title: "Name",
          description: "Description",
          members_count: "Num. of members",
          updated_at: "Updated at",
          last_modified: "Last modified",
          data_is_missing: "Table is empty"
        }
      },
      details: {
        header: {
          back_button: "Roles list",
          edit_button: "Edit role",
          toggle: {
            title: "Active",
            activate: "activate",
            deactivate: "deactivate",
            description: "You can {action} role here"
          }
        },
        main: {
          active: "Active",
          inactive: "Inactive",
          overview: {
            author: "Author",
            created_at: "Created at",
            updated_at: "Updated at",
            last_modified: "Last modified"
          }
        }
      }
    },
    popup: {
      title: "New Role",
      fields: {
        title: "Name",
        description: "Description"
      },
      add_button: "Add role",
      update_button: "Update role"
    },
    prompts: {
      delete: {
        title: "Delete {title}",
        message: "You're about to delete {title}. Would you like to proceed with this action?",
        cancel: "Cancel",
        confirm: "Delete"
      }
    },
    notify: {
      stored: "Role {title} created",
      updated: "Role {title} updated",
      deleted: "Role {title} was deleted",
      not_found: "Unable to find specified role"
    },
    users: {
      list: {
        title: "Role members",
        table: {
          user: "Name",
          added_at: "Added",
          remove_button: "Remove"
        },
        add_button: "Add users",
        empty_notice: "Role has no members."
      },
      add_drawer: {
        title: "Users list",
        table: {
          name: "Name",
          username: "Username",
          email: "Email"
        },
        search_placeholder: "Search ...",
        users_count: "Users count: {count}",
        cancel_button: "Cancel",
        confirm_button: "Add selected users"
      }
    },
    roles: {
      list: {
        title: "Member of",
        table: {
          user: "Name",
          added_at: "Added",
          remove_button: "Remove"
        },
        add_button: "Add roles",
        empty_notice: "User is not part of any roles."
      },
      add_drawer: {
        title: "Roles list",
        table: {
          name: "Name",
          description: "Description"
        },
        search_placeholder: "Search ...",
        users_count: "Roles count: {count}",
        cancel_button: "Cancel",
        confirm_button: "Add selected roles"
      }
    }
  }
}
