export default {
  views: {
    list: {
      header: {
        title: "Company licenses",
        search_placeholder: "Search ...",
        add_button: "Start here"
      },
      table: {
        name: "Name",
        url: "URL to your company CRM",
        created_at: "Created at",
        updated_at: "Updated at"
      }
    },
    details: {
      header: {
        back_button: "Company licenses list"
      },
      main: {
        active: "Active",
        inactive: "Inactive",
        update_tenant: "Update company CRM",
        update_error: "An error occured when updating company CRM",
        update_success: "Company CRM updated successfully",
        overview: {
          author: "Author",
          created_at: "Created at",
          updated_at: "Updated at",
          last_modified: "Last modified"
        }
      }
    },
    New: {
      back: "Back",
      next: "Next",
      create: "Create company CRM",
      creating: "Creating company CRM",
      save_error: "There was an error when creating the company CRM",
      header: {
        back_button: "Company licenses list",
        title: "New company CRM setup",
        licensing_plan_total: "Licensing plan total"
      }
    }
  },
  popup: {
    title: "New company CRM",
    fields: {
      title: {
        label: "Title"
      }
    },
    add_button: "Add comapny CRM",
    update_button: "Update comapny CRM"
  },
  prompts: {
    delete: {
      title: "Delete {title}",
      message: "You're about to delete {title}. Would you like to proceed with this action?",
      cancel: "Cancel",
      confirm: "Delete"
    }
  },
  notify: {
    stored: "Company CRM {title} created",
    updated: "Company CRM {title} updated",
    deleted: "Company CRM {title} was deleted",
    not_found: "Unable to find specified company CRM"
  },
  Components: {
    Form: {
      Form: {
        title: "Application setup",
        back_button: "",
        save_button: "Save settings",
        save_error: "An error occurred while saving",
        save_success: "Successfully saved",
        Application: {
          title: "Basic data",
          label: {
            name: "Company name",
            company: "Company",
            url: "Application URL",
            language: "Language",
            email: "Email",
            phone: "Phone",
            password: "Password",
            confirm_password: "Confirm password"
          },
          address: {
            vat_number: "VAT number",
            title: "Address",
            address: "Address",
            postal: "Postal",
            city: "City",
            country: "Country",
            phone: "Phone",
            phone_country_code: "Country Code"
          }
        },
        DataProviders: {
          title: "Data provider",
          description: 'Import data from supported providers.<br/><a href="https://acronx.com/video-library/#section-17-654" target="_blank">How to import data</a>',
          error_checking_pantheon_connection: "There was an error while checking the pantheon connection.",
          pantheon_status: {
            untested: "Untested",
            valid: "Working",
            invalid: "Not working",
            testing: "Testing..."
          },
          label: {
            selected_provider: "Select data provider",
            host: "Host address",
            port: "Port",
            database: "Database name",
            username: "Database username",
            password: "Database password",
            connection_test: "Database connection"
          },
          test_connection_button: "Check connection"
        },
        Plugins: {
          title: "Plugins",
          description: "Currenlty supported providers. More coming soon.",
          link: "Link",
          registration: "Registration",
          newsletter: {
            title: "Newsletter",
            description: 'Newsletter provider will provide newsletter support.<br/><a href="https://acronx.com/video-library/#section-379-654" target="_blank">How to set up newsletter support</a>',
            selected_provider: "Select data provider",
            squalomail: {
              description: "SqualoMail is an email marketing platform.",
              link: "https://www.squalomail.com/sl/",
              registration: "https://register.squalomail.com/free-trial/sl/",
              key: "API KEY"
            }
          },
          business_data: {
            title: "Business data",
            description: 'Business data provider will autofill data for business customers.<br/><a href="https://acronx.com/video-library/#section-381-654" target="_blank">How to set up data autofill for business customers</a>',
            selected_provider: "Select data provider",
            bisnode: {
              description: "Bisnode is provider of local compnay data.",
              link: "https://bisnode.si/",
              registration: "https://accounts.bisnode.si/Authenticate/?product=0&language=en-US&returnUrl=https%3A%2F%2Faccounts.bisnode.si%2FHome%2F%3Fproduct%3D0%26language%3Dsl-SI%26basLanguage%3Den-US",
              username: "Username",
              password: "Password"
            }
          },
          mailbox: {
            title: "Mailbox",
            description: "Mailbox will connect incomming emails to existing entities.",
            host: "Host",
            port: "Port (SSL IMAP)",
            username: "Username",
            password: "Password"
          }
        },
        Subscription: {
          licences_plan: "Subscription plan",
          licences_plan_description: 'Please select your subscription plan. You can decide between monthly and yearly options. With a yearly subscription we provide a discount.<br/><a href="https://acronx.com/video-library/#section-387-654" target="_blank">How to choose a subscription</a>',
          free_trial_card_title: "Free trial",
          free_trial_card_description: "Choose this option to start your unlimited free 40 day trial!",
          paid_licensing_plan_card_title: "Paid licensing plan",
          paid_licensing_plan_card_description: "Choose this option to start your paid subscription",
          title: "Licensing plan",
          monthly: "Monthly",
          yearly: "Yearly",
          licences: "Licences",
          licences_description: "The base licence required by each user. Choose at least one.",
          modules: "Modules",
          modules_description: "Module licences that allow you to enable modules for individual users. (Optional)",
          assets: "Assets",
          assets_description: "Asset amounts that allow you purchase a number of assets that can be used by all users. (Optional)",
          total_title: "Licensing plan total:",
          free_for_days: "* for the first 40 days",
          calculating_differences: "Calculating subscription payment differences, please hold on.",
          no_tax_subscription: "Total subscription price without tax:",
          tax_subscription: "Total tax:",
          total_subscription: "Total subscription price:",
          change_from_previous: "Change from previous:",
          current_balance: "Your current stripe balance:",
          balance_after_change: "Your stripe balance after subscription update:",
          update_to_pay: "Payment amount after subscription update:",
          change_tooltip: "Negative numbers represent the amount that will be added to your stripe invoice credit balance and used up with your next purchase.",
          balance_tooltip: "If you would like to get it refunded, please contact the administrator.",
          total_tooltip: "This is the amount you will pay in the next billing period.",

          Components: {
            PlanCard: {
              used: "{used} used",
              info: "You cannot reduce the number of licenses below the number of used."
            }
          },

          proration_preview_error: "An error occured. Check your data, especially Country & VAT number."
        },
        Payment: {
          title: "Payment",
          subtitle: "Choose or create a payment method",
          trial_describtion: "You've selected the free trial.<br><br>You can finish the setup process by clicking  the \"Create tenant\" button below.",
          Components: {
            StripePaymentForm: {
              pay_now: "Pay now"
            },
            PaymentMethods: {
              add_payment_method: "Add payment method",
              Components: {
                AddPaymentMethodForm: {
                  title: "Add payment method",
                  add_button: "Add payment method",
                  cardholder_name: "Cardholder name"
                }
              }
            }
          }
        }
      }
    }
  }
}
