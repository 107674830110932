import Vue from "vue"
import Vuex from "vuex"
import space from "./store/layout"
import Auth from "@/Modules/Auth/Auth/Resources/Auth"

Vue.use(Vuex)

let getApiUrl = () => {
  if (process.env.NODE_ENV === "production") {
    return (!process.env.VUE_APP_NO_LOCATION_API_URL ? location.origin + "/" : "") + process.env.VUE_APP_API
  }
  return process.env.VUE_APP_API
}

export default new Vuex.Store({
  state: {
    apiUrl: getApiUrl(),
    init: null
  },
  actions: {
    async init ({ state }) {
      state.init = window.axios.get("/auth/user")
    }
  },
  modules: {
    space,
    Auth
  }
})
