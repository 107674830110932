import { ifNotAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/registration/activate/:token",
    name: "Activation",
    meta: { title: "Activate account" },
    component: () => import(/* webpackChunkName: "Activation" */ "@/Modules/Auth/Registration/Views/Activation.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/registration/activation-success",
    name: "ActivationSuccess",
    meta: { title: "Activation Successful" },
    component: () => import(/* webpackChunkName: "ActivationSucces" */ "@/Modules/Auth/Registration/Views/ActivationSuccess.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/registration/activation-error",
    name: "ActivationError",
    meta: { title: "Activation Error" },
    component: () => import(/* webpackChunkName: "ActivationError" */ "@/Modules/Auth/Registration/Views/ActivationError.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/registration/registration-success",
    name: "RegistrationSuccess",
    meta: { title: "Registration Successful" },
    component: () => import(/* webpackChunkName: "ActivationSucces" */ "@/Modules/Auth/Registration/Views/RegistrationSuccess.vue"),
    beforeEnter: ifNotAuthenticated
  },
  {
    path: "/registration",
    name: "Registration",
    meta: { title: "Registration" },
    component: () => import(/* webpackChunkName: "Registration" */ "@/Modules/Auth/Registration/Views/Registration.vue"),
    beforeEnter: ifNotAuthenticated
  }
]
