<template>
    <Hook
        v-if="isAuthenticated"
        @created="authenticatedChanged()"
        @beforeDestroy="authenticatedChanged()"
    />
</template>

<script>
import Hook from "@/Components/Hook"

export default {
  components: { Hook },
  computed: {
    isAuthenticated () {
      return this.$api.Auth.isAuthenticated
    }
  },
  async created () {
    await this.init()
    this.interceptor()
    this.startRefreshInterval()

    this.$emit("ready")
  },
  methods: {
    /**
     * Check user auth status
     */
    async init () {
      try {
        const response = await this.$api.Auth.init(this.$store.state.init)
        this.handleResponse(response)
      } catch (e) {
        // No need to report on this error
        // it means that user is not loggedin
        // - that is all
      }
    },
    async check () {
      const response = await this.$api.Auth.check()
      this.handleResponse(response)
    },
    handleResponse (response) {
      if (response !== undefined && response.status !== 200) {
        if (!this.$route.path.startsWith("/login")) {
          this.$router.push("/login")
        }
      }
    },
    /**
     * Refresh cookie interval
     */
    startRefreshInterval () {
      const interval = 1000 * 60 * process.env.VUE_APP_COOKIE_REFRESH
      setInterval(() => this.check(), interval)
    },
    /**
     * Incase of 401, logout the user
     * this is Axios interceptor on every request
     */
    interceptor () {
      let isFetchingAccessToken = false
      window.axios.interceptors.response.use(
        (response) => response,
        (error) => {
          const { config } = error
          const originalRequest = config

          if (error.response.status !== 401) {
            return Promise.reject(error)
          }

          if (!isFetchingAccessToken) {
            isFetchingAccessToken = true
            this.check().then(() => {
              isFetchingAccessToken = false
            })
          }

          const retryOriginalRequest = new Promise((resolve) => {
            resolve(window.axios(originalRequest))
          })
          return retryOriginalRequest

          // if (Object.keys(this.$api.Auth.user).length === 0) {
          //   this.$api.Auth.logout();
          //   return;
          // }
          // /*
          //  * When response code is 401, try to refresh the token.
          //  * Eject the interceptor so it doesn't loop in case
          //  * token refresh causes the 401 response
          //  */
          // window.axios.interceptors.response.eject(authInterceptor);
          // const originalRequest = error.config;

          // if (!error.config.__isRetryRequest) {
          //   // Retry with original request
          //   return new Promise((resolve) => {
          //     error.config.__isRetryRequest = true;
          //     resolve(window.axios(originalRequest));
          //   });
          // }
          // this.$api.Auth.logout();
        }
      )
    },
    authenticatedChanged () {
      const state = this.isAuthenticated

      // if (state) this.getSettings()
      // else this.$api.Settings.clearSettings()

      if (!state && !this.$route.path.startsWith("/login")) {
        this.$router.push("/login")
      }
      return false
    }
    // async getSettings () {
    //   let res

    //   if (this.isAuthenticated) {
    //     res = await this.$api.Settings.get()
    //   }

    //   return res
    // }
  }
}
</script>
