import store from "@/providers/store"
/**
 * Route guard
 * We will set guards here, for checking if
 * the user has the right access level for
 * entering a specific route
 */

async function initStateHelper () {
  try {
    const response = await store.state.init
    return response
  } catch (e) {

  }
}

export async function ifAuthenticated (to, from, next) {
  /**
   * Not sure why this works, but it does.
   * Without "store.state.init" auth user is not
   * redirected from login page (if you go there after login)
   */
  await initStateHelper()

  if (store.getters["Auth/isAuthenticated"]) {
    next()
    return
  }
  next("/login")
}

export async function ifNotAuthenticated (to, from, next) {
  await initStateHelper()

  if ((to.path.startsWith("/login") || to.path.startsWith("/registration")) && store.getters["Auth/isAuthenticated"]) {
    next("/")
    return
  }

  if (!store.getters["Auth/isAuthenticated"]) {
    next()
    return
  }
  next(document.location.pathname)
}
