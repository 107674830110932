export default [
  {
    path: "/",
    redirect: "/tenants" // default route
  },
  {
    path: "*",
    redirect: "/" // 404
  }
]
