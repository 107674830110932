export default {
  registration: {
    views: {
      registration: {
        title_success: "Registration successful",
        confirmation: "A confirmation email has been sent to your email address.",
        confirm_error: "The password confirmation does not match."
      }
    }
  }
}
