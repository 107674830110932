export default {
  registration: {
    views: {
      registration: {
        title_success: "Registracija uspešna",
        confirmation: "Na vaš e-poštni naslov je bilo poslano potrditveno e-poštno sporočilo.",
        confirm_error: "Potrditveno geslo se ne ujema."
      }
    }
  }
}
