import { ifAuthenticated } from "@/Modules/Auth/Auth/Routes/guards"

export default [
  {
    path: "/tenants",
    name: "Tenants",
    meta: { title: "Tenants" },
    component: () => import(/* webpackChunkName: "Tenants List" */ "@/Modules/Tenant/Views/List/List"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/tenants/new",
    name: "NewTenant",
    meta: { title: "New Tenant" },
    component: () => import(/* webpackChunkName: "New Tenant" */ "@/Modules/Tenant/Views/New/New"),
    beforeEnter: ifAuthenticated
  },
  {
    path: "/tenants/details/:id",
    name: "Tenant Details",
    component: () => import(/* webpackChunkName: "Tenant Details" */ "@/Modules/Tenant/Views/Details/Details"),
    beforeEnter: ifAuthenticated
  }
]
