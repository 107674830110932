export default {
  role: {
    views: {
      list: {
        header: {
          title: "Uporabniške skupine",
          search_placeholder: "Iskanje ...",
          add_button: "Dodaj uporabniško skupino"
        },
        table: {
          title: "Ime",
          description: "Opis",
          members_count: "Število članov",
          updated_at: "Posodobljeno",
          last_modified: "Nazadnje posodobljeno",
          data_is_missing: "Tabela je prazna"
        }
      },
      details: {
        header: {
          back_button: "Seznam uporabniških skupin",
          edit_button: "Uredite upoorabniške skupine",
          toggle: {
            title: "Aktiven",
            activate: "aktivirajte",
            deactivate: "deaktivirajte",
            description: "Tu lahko {action} uporabniško skupino"
          }
        },
        main: {
          active: "Aktiven",
          inactive: "Neaktiven",
          overview: {
            author: "Avtor",
            created_at: "Ustvarjen",
            updated_at: "Posodobljen",
            last_modified: "Nazadnje spremenjeno"
          }
        }
      }
    },
    popup: {
      title: "Nova uporabniška skupina",
      fields: {
        title: "Ime",
        description: "Opis"
      },
      add_button: "Dodaj uporabniško skupino",
      update_button: "Posodobite uporabniško skupino"
    },
    prompts: {
      delete: {
        title: "Izbriši {title}",
        message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
        cancel: "Prekliči",
        confirm: "Izbriši"
      }
    },
    notify: {
      stored: "Uporabniška skupina {title} ustvarjena",
      updated: "Uporabniška skupina {title} posodobljena",
      deleted: "Uporabniška skupina {title} je bila izbrisana",
      not_found: "Navedene vloge ni mogoče najti"
    },
    users: {
      list: {
        title: "Člani uporabniške skupine",
        table: {
          user: "Ime",
          added_at: "Dodano",
          remove_button: "Odstrani"
        },
        add_button: "Dodaj uporabnike",
        empty_notice: "Uporabniška skupina nima članov."
      },
      add_drawer: {
        title: "Seznam uporabnikov",
        table: {
          name: "Ime",
          username: "Uporabniško ime",
          email: "Email"
        },
        search_placeholder: "Iskanje ...",
        users_count: "Uporabniki count: {count}",
        cancel_button: "Prekliči",
        confirm_button: "Dodajte izbrane uporabnike"
      }
    },
    roles: {
      list: {
        title: "Član",
        table: {
          user: "Ime",
          added_at: "Dodan",
          remove_button: "Odstranite"
        },
        add_button: "Dodaj uporabniško skupino",
        empty_notice: "Uporabnik ni del nobene vloge."
      },
      add_drawer: {
        title: "Seznam uporabniških skupin",
        table: {
          name: "Ime",
          description: "Opis"
        },
        search_placeholder: "Iskanje ...",
        users_count: "Uporabniške skupine count: {count}",
        cancel_button: "Prekliči",
        confirm_button: "Dodajte izbrane uporabniške skupine"
      }
    }
  }
}
