<template>
    <ResourceSelect
        class="customer-select"
        :value="value"
        :resource="resource"
        :remaps="{ key: 'id', title: 'title', subtitle: '_contact' }"
        :search-columns="['title']"
        :no-results="noResults"
        :label="label"
        :has-none-option="false"
        :options="options"
        :option-button="{
            icon: 'plus',
            text: $t('Global.customer.add_customer'),
            action: addCustomer
        }"
        avatar
        sort="title"
        @change="$emit('change', $event)"
    />
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "change"
  },
  props: {
    value: { type: [String, Array, Number], default: "" },
    resource: { type: [Object, null], default: null },
    label: { type: String, default: "" },
    placeholder: { type: String, default: "" },
    noResults: { type: Object, default: null }
  },
  computed: {
    options () {
      const list = this.resource?.list
      if (!list) return []

      let options = []

      const getContact = (option) => {
        const contacts = option.contacts
        if (!contacts || !contacts.length) return ""

        let contact = contacts.find(item => item.type === "email" && !!item.value)
        if (!contact) contact = contacts.find(item => item.type === "phone" && !!item.value)
        if (!contact) return ""

        return contact.value
      }

      for (let i = 0; i < list.length; i++) {
        const option = list[i]
        options.push({
          ...option,
          _contact: getContact(option)
        })
      }

      return options
    }
  },
  methods: {
    addCustomer () {
      if (this.noResults?.button?.action) {
        this.noResults.button.action()
        return
      }

      this.$router.push({ name: "New Subject" })
    }
  }
}
</script>

<style lang="scss">
.customer-select {
  .uik-avatar__wrapper {
    margin: 0 !important;

    .uik-avatar__avatarWrapper {
      display: none !important;
    }

    .uik-avatar__info {
      margin-left: 0 !important;
      max-width: 100% !important;

      .uik-avatar__name {
        white-space: initial !important;
        font-weight: 600;
        color: $textMain;
        line-height: 17px;
      }

      .uik-avatar__textBottom {
        line-height: 17px;
      }
    }
  }

  .uik-select__optionListWrapper {
    max-height: unset !important;

    .uik-select__optionList {
      max-height: 289px;
    }
  }

  .uik-select__option {
    min-height: 37px !important;
    height: auto !important;
  }
}
</style>
