export default {
  confirm: "Potrdi",
  cancel: "Prekliči",
  close: "Zapri",
  add: "Dodaj",
  save: "Shrani",
  remove: "Odstrani",
  delete: "Izbriši",
  open: "Odpri",
  edit: "Uredi",
  duplicate: "Podvojite",
  activate: "Aktiviraj",
  deactivate: "Deaktiviraj",
  none: "Nič",
  logged_in_user: "Prijavljen uporabnik",
  passwordRule: "Geslo mora biti dolgo vsaj 6 znakov <br/> in vsebovati črko, številko in simbol(!@#$%^&*?-=+_)",
  customer: {
    customer: "Stranka",
    recipient: "Prejemnik",
    select_subject: "Izberite stranko",
    select_recipient: "Izberite prejemnika",
    name: "Ime",
    address: "Naslov",
    email: "E-mail",
    phone: "Telefonska številka",
    user: {
      no_results_message: "Ta uporabnik ne obstaja",
      no_results_button: "Ustvarite novega uporabnika"
    },
    subject_no_result_data: {
      no_results_message: "Ta stranka ne obstaja",
      no_results_button: "Ustvarite novo stranko"
    },
    add_customer: "Dodajte novo stranko"
  },
  colors: {
    red: "Rdeča",
    orange: "Oranžna",
    yellow: "Rumena",
    lime: "Limetina zelena",
    green: "Zelena",
    cyan: "Cyan",
    blue: "Modra",
    purple: "Vijolična",
    pink: "Roza",
    gray: "Siva"
  },
  menu: {
    views: {
      tenants: "Licence"
    }
  },
  components: {
    creation_info: {
      creation_info: "Informacije o ustvarjanju",
      created_at: "Datum ustvaritve",
      created_by: "Avtor uporabnika",
      updated_at: "Datum spremembe",
      updated_by: "Uporabnik nazadnje spremenjen"
    },
    contact_info: {
      address: {
        title: "Naslov",
        remove_button: "Odstrani naslov",
        add_button: "Dodaj naslov",
        address: "Naslov",
        postal: "Poštna številka",
        city: "Mesto",
        country: "Država"
      },
      contact: {
        title: "Kontakt",
        remove_button: "Odstrani kontakt",
        add_button: "Dodaj kontakt",
        contact: "Kontakt",
        phone: "Telefonska številka",
        email: "E-mail",
        any: "Kontakt",
        type: "Tip",
        label: "Dodatna oznaka"
      }
    },
    multiple_entries_check: {
      label: "Želite dodati še enega?",
      description: "Obrazec bo po potrditvi ostal odprt"
    },
    recurrence: {
      form: {
        type: "Interval",
        types: {
          hourly: "Vsako uro",
          daily: "Dnevno",
          weekly: "Tedensko",
          monthly: "Mesečno",
          yearly: "Letno"
        },
        recurrence_headline: "Ponovitev {number}",
        remove_button: "Odstranite ponovitev",
        add_button: "Dodajte ponovitev",
        time: "Čas",
        minutes: "Minute",
        weekday: "Dan",
        weekdays: {
          monday: "Ponedeljek",
          tuesday: "Torek",
          wednesday: "Sreda",
          thursday: "Četrtek",
          friday: "Petek",
          saturday: "Sobota",
          sunday: "Nedelja"
        },
        day: "Dan",
        first_day_of_month: "Prvi dan v mesecu",
        last_day_of_month: "Zadnji dan v mesecu",
        month: "Mesec",
        months: {
          january: "Januar",
          february: "Februar",
          march: "Marec",
          april: "April",
          may: "Maj",
          june: "Junij",
          july: "Julij",
          august: "Avgust",
          september: "September",
          october: "Oktober",
          november: "November",
          december: "December"
        }
      }
    },
    query_builder: {
      title: "Najdi {models}, kjer ...",
      type: {
        string: "Tekst",
        number: "Številka",
        boolean: "Preklop",
        date: "Datum",
        enum: "Enum",
        relation: "Razmerje"
      },
      comparison_operators: {
        string: {
          "=": "Je",
          "!=": "Ni",
          contains: "Vsebuje",
          starts_with: "Začne se z",
          ends_with: "Konča se z",
          is_empty: "Je prazno",
          is_not_empty: "Ni prazno"
        },
        number: {
          "=": "=",
          "!=": "≠",
          "<": "<",
          ">": ">",
          "<=": "≤",
          ">=": "≥",
          exists: "Obstaja",
          not_exists: "Ne obstaja"
        },
        boolean: {
          "=": "Je",
          "!=": "Ni"
        },
        date: {
          "=": "Je",
          "!=": "Ni",
          "<": "Je pred",
          ">": "Je po",
          "<=": "Je na ali pred",
          ">=": "Je na ali po",
          exists: "Obstaja",
          not_exists: "Ne obstaja"
        },
        enum: {
          "=": "Je",
          "!=": "Ni",
          is_empty: "Je prazno",
          is_not_empty: "Ni prazno"
        },
        relation_one: {
          exists: "Obstaja",
          not_exists: "Ne obstaja",
          exists_where: "Obstaja kjer...",
          not_exists_where: "Ne obstaja kjer..."
        },
        relation_many: {
          exists: "Obstaja",
          not_exists: "Ne obstaja",
          exists_where: "Obstaja kjer...",
          not_exists_where: "Ne obstaja kjer..."
        }
      },
      logical_operators: {
        and: "In",
        or: "Ali"
      },
      property_placeholder: "Izberite lastnost",
      boolean: {
        true: "Ja",
        false: "Ne"
      },
      date: {
        type: {
          today: "Danes",
          tomorrow: "Jutri",
          yesterday: "Včeraj",
          week_ago: "Pred enim tednom",
          week_from_now: "Čez en teden",
          month_ago: "Pred enim mesecom",
          month_from_now: "Čez en mesec",
          now: "Sedaj",
          hour_ago: "Pred eno uro",
          hour_from_now: "Čez en mesec",
          "12h_ago": "pred 12 urami",
          "12h_from_now": "čez 12 ur",
          this_year: "To leto",
          year_ago: "Pred enim letom",
          year_from_now: "Čez eno leto",
          date: "Točen datum",
          datetime: "Točen datum in ura",
          time: "Točen čas",
          month: "Točen mesec",
          year: "Točno leto"
        }
      },
      relation: {
        results_count: "Število najdenih rezultatov"
      },
      add_filter_button: "Dodajte filter",
      add_filter_group_button: "Dodajte skupino filtrov",
      actions: {
        duplicate: "Podvojite",
        turn_into_group: "Spremenite v skupino",
        release_group: "Izpusti skupino",
        remove: "Odstrani"
      }
    },
    pantheon_widget: {
      title: "Pantheon",
      saved: {
        title: "Shranjeno v Pantheonu",
        description: "{title} je bil uspešno shranjen v Pantheon na {date}, ob {time}.\n",
        button: "Shrani sedaj"
      },
      not_saved: {
        title: "Ni shranjeno v Pantheonu",
        description: "{title} še ni shranjen v Panteonu.",
        button: "Shrani v Pantheon"
      },
      error: {
        title: "Napaka pri sinhronizaciji Pantheona",
        description: "Sinhronizacija Pantheon je naletela na napako. To je lahko posledica tega, da Pantheon v času sinhronizacije ni bil na voljo.\nZadnje uspešno shranjevanje se je zgodilo {date}, ob {time}.\nPoskusite znova shraniti. Če težava ni odpravljena, se obrnite na skrbnika.",
        button: "Shrani v Pantheon"
      }
    },
    query_builder_form_section: {
      title: "Filter",
      description: "Za zoženje rezultatov uporabite filtre.",
      add_button: "Nastavite filtre",
      edit_button: "Uredite filtre",
      remove_button: "Odstranite filtre",
      popup_title: "Najdi predmete kjer ..."
    }
  },
  trial_bar_messages: {
    expire: "Vaša licenca bo potekla v",
    day: "dnevu",
    days: "dneh",
    redirect: "Licenco lahko podaljšate s klikom na povezavo.",
    contact_admin: "Kontaktirajte administratorja",
    extend_licence: "za podaljšanje vaše licence."
  },
  top_bar_with_url: {
    click_here: "Kliknite tukaj"
  }
}
