<template>
    <nav
        class="navigation"
        :class="{
            'navigation--open': openNav,
            'navigation--collapsed': collapsed,
        }"
    >
        <div class="navigation__container">
            <div class="navigation__top">
                <div class="navigation__logo">
                    <Logo @click.native="$router.push('/')" />
                    <span class="navigation__version">{{ appVersion }}</span>
                    <button class="navigation__toggleCollapse" @click="toggleCollapse()">
                        <UikIcon :icon="['fas', 'angle-double-left']" />
                    </button>
                </div>
                <div class="navigation__user">
                    <UikAvatar
                        :name="user.full_name"
                        :text-bottom="
                            user.position_title ? user.position_title : user.email
                        "
                        :image-url="user.avatar ? $root.appStorage + user.avatar : ''"
                        imageOnly
                        @click.native="
                            $router.push('/settings');
                            openNav = false;
                        "
                    />
                    <h2>{{ user.full_name ? user.full_name : "Povezovanje ..." }}</h2>
                    <h3>{{ user.position_title ? user.position_title : user.email }}</h3>
                </div>
            </div>
            <div class="navigation__scroll-wrapper">
                <div class="navigation__views">
                    <router-link
                        v-for="(link, index) in getLinks"
                        :key="`link-${index}`"
                        :title="t(`views.${link.route}`)"
                        :to="`/${link.route}`"
                        @click.native="openNav = false"
                    >
                        <div>
                            <UikIcon :icon="[iconStyle(`/${link.route}`), link.icon]"/>
                            <span>{{ t(`views.${link.route}`) }}</span>
                        </div>

                        <div>
                            <div
                                v-if="link.route === 'tasks' && taskIndicators.opened"
                                class="navigation__task-indicator-open"
                            >
                                {{  taskIndicators.opened }}
                            </div>
                            <div
                                v-if="link.route === 'tasks' && taskIndicators.expired"
                                class="navigation__task-indicator-expired"
                            >
                                {{ taskIndicators.expired }}
                            </div>
                        </div>
                    </router-link>
                </div>
                <div class="navigation__bottom">
                    <router-link
                        to="/settings"
                        :title="collapsed ? 'Settings' : ''"
                        @click.native="openNav = false"
                    >
                        <UikIcon :icon="[iconStyle('/settings'), 'cog']" />
                        <span>Settings</span>
                    </router-link>
                    <button
                        :title="collapsed ? 'Log Out' : ''"
                        @click="
                            openNav = false;
                            $api.Auth.logout();
                        "
                    >
                        <UikIcon :icon="['far', 'sign-out-alt']" />
                        <span>Log out</span>
                    </button>
                </div>
            </div>
        </div>
        <div class="navigation__mobile">
            <div class="navigation__mobile-btn-placeholder" />
            <Logo class="navigation__mobile-logo" @click.native="$router.push('/')" />
            <UikAvatar
                :name="user.name"
                :text-bottom="user.position_title ? user.position_title : user.email"
                :image-url="user.avatar ? $root.appStorage + user.avatar : ''"
                imageOnly
                primary
                @click.native="$router.push('/settings')"
            />
        </div>
        <button class="navigation__toggleNav" @click="openNav = !openNav">
            <div v-for="n in 3" :key="n" />
        </button>
    </nav>
</template>

<script>
import Logo from "@/Assets/Logo.vue"

export default {
  langKey: "Global.menu",
  links: [
    {
      route: "tenants",
      icon: "browser"
    }
  ],
  components: { Logo },
  data () {
    return {
      openNav: false,
      collapsed: false,

      taskIndicators: {
        opened: null,
        expired: null
      }
    }
  },
  computed: {
    user () {
      return this.$api.Auth && this.$api.Auth.state && this.$api.Auth.state.user
        ? this.$api.Auth.state.user
        : {}
    },
    userPermissions () {
      if (!this.user.permissions) return []
      return this.user.permissions
    },
    getLinks () {
      return this.$options.links
    },
    appVersion () {
      return process.env.VUE_APP_VERSION || ""
    }
  },
  async created () {
    this.collapsed = localStorage.menuCollapsed === "true"
    // this.$api.Settings.get("NavigationViews")
    // this.$api.Settings.get("LangAndRegion")
  },
  methods: {
    iconStyle (path, exact) {
      let style = "far"
      if (
        (exact && this.$route.path === path) ||
        (!exact && this.$route.path.startsWith(path))
      ) {
        style = "fas"
      }
      return style
    },
    hasPermission (index) {
      if (Object.keys(this.userPermissions).length > 0) {
        return this.userPermissions.indexOf("view/" + index) >= 0
      }
      return false
    },
    toggleCollapse () {
      this.collapsed = !this.collapsed
      localStorage.menuCollapsed = this.collapsed
    }
  }
}
</script>

<style lang="scss">
.navigation {
  $sectionBorderColor: #3b3b3f;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  max-width: 260px;
  transition: left 0.5s, max-width 0.3s ease-out;
  .navigation__container {
    width: 100%;
    height: 100%;
    background: $bgDark;
    background: linear-gradient(0deg, $bgDark, $bgDarkLighter);
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    .navigation__top {
      width: 100%;
      @media only #{$mediaTablet} {
        padding-right: 0;
      }
      .navigation__logo {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 25px 0 20px;
        height: $headerHeightDefault;
        position: relative;
        svg {
          height: 17px;
          cursor: pointer;
        }

        .logo {
          height: 16px;
          width: 102px;
        }

        .navigation__version {
          position: absolute;
          top: 50px;
          font-size: 10px;
          font-weight: bold;
        }

        .navigation__toggleCollapse {
          display: flex;
          flex-flow: row nowrap;
          justify-content: center;
          align-items: center;
          width: 34px;
          height: 34px;
          background: transparent;
          outline: none;
          border: none;
          transition: all 0.2s;
          border-radius: 50%;
          padding-right: 1px;
          position: absolute;
          right: 17px;
          color: $textSecondary;
          &:hover {
            cursor: pointer;
            color: whitesmoke;
            // background: $sectionBorderColor;
          }
          &:active {
            transform: scale(0.9);
          }
          svg {
            height: 22px;
            font-size: 22px;
          }
        }
      }
      .navigation__user {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        padding: 8px 25px 23px 20px;
        transition: width 0.14s;
        width: 100%;
        .uik-avatar__wrapper {
          display: flex;
          flex-flow: column nowrap;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .uik-avatar__avatarWrapper {
            pointer-events: none;
            width: 66px;
            height: 66px;
            border-radius: 50%;
            min-width: 66px;
            padding: 0;
            transition: all 0.2s;
            box-shadow: 0 3px 15px -5px rgba(black, 0.4);
            .uik-avatar__avatar {
              border-radius: 50%;
            }
            .uik-avatar__avatarPlaceholder {
              background: $sectionBorderColor;
              border-color: $sectionBorderColor;
              border-radius: 50%;
              color: $textLight;
              font-size: 20px;
              line-height: 1;
              font-weight: 600;
            }
          }
          .uik-avatar__info {
            text-align: center;
            margin-left: 0;
            margin-top: 15px;
            width: 100%;
            .uik-avatar__name {
              color: white;
              font-size: 14px;
              font-weight: 500;
              line-height: 1.2;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100%;
              display: block;
            }
            .uik-avatar__textBottom {
              color: $textSecondary;
              font-size: 12px;
              font-weight: 500;
              line-height: 1.2;
              margin-top: 5px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 100%;
            }
          }
          &:hover {
            .uik-avatar__avatarWrapper {
              transform: scale(1.05);
              box-shadow: 0 5px 20px -5px rgba(black, 0.5);
            }
          }
          &:active {
            .uik-avatar__avatarWrapper {
              box-shadow: 0 2px 5px 0 rgba(black, 0.25);
              transform: scale(0.95);
            }
          }
        }
        h2 {
          margin-top: 15px;
          width: 100%;
          text-align: center;
          color: white;
          font-size: 15px;
          font-weight: 500;
          line-height: 1.4;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          display: block;
        }
        h3 {
          color: $textSecondary;
          font-size: 12px;
          font-weight: 500;
          line-height: 1.4;
          margin-top: 2px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          width: 100%;
          text-align: center;
        }
      }
    }
    .navigation__scroll-wrapper {
      overflow: auto;
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      padding: 10px 0;
      flex-grow: 1;
      &::-webkit-scrollbar-track {
        display: initial;
      }
      &::-webkit-scrollbar {
        display: none;
        border-left: none;
      }
      &::-webkit-scrollbar-thumb {
        background: rgba($textSecondary, 0.4);
        border: none;
        &:hover {
          background: rgba($textSecondary, 0.5);
        }
        &:active {
          background: rgba($textSecondary, 0.6);
        }
      }

      &:hover {
        &::-webkit-scrollbar {
          display: initial;
          background: $bgDark;
        }
      }
    }
    .navigation__views,
    .navigation__bottom {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      a,
      button {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        padding: 16px 25px;
        text-decoration: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.2;
        width: 100%;
        border-radius: 0;
        border: none;
        outline: none;
        background: transparent;
        transition: background-color 0.1s, transform 0.1s;
        border-left: solid 0px transparent;
        position: relative;
        svg {
          font-size: 16px;
          width: 18px;
          transition: color 0.1s;
          color: rgba($textSecondary, 0.8);
        }
        span {
          color: $textSecondary;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 100%;
          margin-left: 12px;
          transition: all 0.1s;
        }
        &:hover {
          transition: all 0.05s;
          cursor: pointer;
          background: rgba(#3b3b3f, 0.6);
          svg {
            color: rgba(white, 0.75);
            transition: all 0.05s;
          }
          span {
            color: white;
            transition: all 0.05s;
          }
        }
        &:active {
          transition: all 0.1s;
          background: rgba(#3b3b3f, 0.8);
        }
        &.router-link-active,
        &.router-link-exact-active {
          background: rgba(#3b3b3f, 0.6);
          svg {
            color: lighten($primary, 2%);
            animation: navigation-active-icon 0.2s;
            @keyframes navigation-active-icon {
              from {
                opacity: 0.5;
                transform: scale(0.6);
              }
            }
          }
          span {
            color: white;
          }
        }
        & + a,
        & + button {
          margin-top: 1px;
        }
      }
    }
    .navigation__views {
      flex-grow: 1;
      padding-bottom: 3px;
      a {
        display: flex;
        justify-content: space-between;
        div {
          display: flex;
        }
        .navigation__task-indicator-open,
        .navigation__task-indicator-expired {
          display: flex;
          justify-content: center;
          text-align: center;
          color: white;
          background: $error;
          min-width: 18px;
          line-height: 18px;
          padding: 0px 2px;
          border-radius: 99px;
          font-size: 10px;
        }
        .navigation__task-indicator-open {
          background: $success;
          margin-right: 3px;
        }
      }
    }
  }
  .navigation__mobile {
    display: none;
    position: fixed;
    z-index: 9;
    width: 100%;
    top: 0;
    left: 0;
    justify-content: space-between;
    align-items: center;
    background: white;
    height: 56px;
    padding: 0 8px;
    border-bottom: $borderDefault;
    .navigation__mobile-btn-placeholder {
      width: 40px;
      min-width: 40px;
      height: 20px;
      pointer-events: none;
      opacity: 0;
      visibility: hidden;
    }
    .navigation__mobile-logo {
      height: 18px;
    }
    .uik-avatar__wrapper {
      padding: 4px;
      cursor: pointer;
      .uik-avatar__avatarWrapper {
        width: 33px;
        height: 33px;
        .uik-avatar__avatarPlaceholder {
          background: lighten($primary, 2%);
          border: none;
          color: white;
          font-size: 13px;
          line-height: 1;
          font-weight: 600;
        }
      }
    }
  }
  .navigation__toggleNav {
    display: none;
    position: fixed;
    left: 8px;
    width: 40px;
    min-width: 40px;
    background: transparent;
    border: none;
    outline: none;
    margin: 0;
    padding: 10px;
    transition: all 0.25s;
    top: 12px;
    z-index: 11;
    div {
      position: relative;
      width: 100%;
      height: 2px;
      background: rgba($textBase, 0.7);
      border-radius: 999px;
      flex-grow: 1;
      transition: all 0.25s;
    }
    div:nth-child(1) {
      margin-top: 0;
      width: 70%;
    }
    div:nth-child(2) {
      margin-top: 3px;
    }
    div:nth-child(3) {
      margin-top: 3px;
      float: right;
      width: 70%;
    }
    &:hover {
      cursor: pointer;
    }
  }
  & + * {
    margin-left: 260px;
    transition: all 0.3s ease-out;
    @media only #{$mediaTablet} {
      margin-left: 0;
      margin-top: 56px;
    }
  }
  @media only #{$mediaTablet} {
    left: -100%;
    max-width: unset;
    .navigation__mobile {
      display: flex;
    }
    .navigation__toggleNav {
      display: block;
    }
    &--open {
      left: 0;
      .navigation__toggleNav {
        div {
          background: white;
        }
        div:nth-child(1) {
          margin-top: 0;
          width: 100%;
          transform: translateX(5px) rotate(45deg) translateY(7px);
        }
        div:nth-child(2) {
          width: 0;
          margin-left: 50%;
        }
        div:nth-child(3) {
          transform: translateX(5px) rotate(-45deg) translateY(-7px);
          width: 100%;
        }
        &:hover {
          cursor: pointer;
          div {
            background: white;
            &:nth-child(1) {
              margin-left: 0;
              width: 100%;
            }
            &:nth-child(3) {
              margin-right: 0;
              width: 100%;
            }
          }
        }
      }
    }
    .navigation__container {
      position: relative;
      z-index: 10;
      .navigation__top {
        .navigation__logo {
          .navigation__toggleCollapse {
            display: none;
          }
        }
      }
    }
  }
  // $mediaTablet
  @media only screen and (min-width: 1024px) {
    &--collapsed {
      max-width: 60px;
      & + * {
        margin-left: 60px;
        @media only #{$mediaTablet} {
          margin-left: 0;
        }
      }
      .navigation__container {
        .navigation__top {
          padding-right: 0;
          .navigation__logo {
            background: rgba($sectionBorderColor, 0.5);
            border-bottom: solid 1px rgba(#202830, 0.5);
            > svg {
              display: none;
            }
            .navigation__toggleCollapse {
              right: 13px;
              transform: scaleX(-1);
              &:hover {
                background: transparent;
              }
              &:active {
                transform: scaleX(-0.9) scaleY(0.9);
              }
            }
          }
          .navigation__user {
            padding: 15px 0 7px 0;
            width: 60px;
            transition: width 0.35s;
            .uik-avatar__wrapper {
              .uik-avatar__avatarWrapper {
                width: 46px;
                height: 46px;
                min-width: 46px;
                padding: 2px;
                background: transparent;
                box-shadow: none;
                .uik-avatar__avatar,
                .uik-avatar__avatarPlaceholder {
                  border-width: 1px;
                }
                .uik-avatar__avatarPlaceholder {
                  font-size: 14px;
                }
              }
              &:hover {
                .uik-avatar__avatarWrapper {
                  box-shadow: none;
                  transform: none;
                  background: lighten($primary, 2%);
                }
              }
              &:active {
                .uik-avatar__avatarWrapper {
                  box-shadow: none;
                  background: rgba(lighten($primary, 2%), 0.5);
                }
              }
            }
            h2,
            h3 {
              display: none;
            }
          }
        }
        .navigation__scroll-wrapper {
          padding: 0;
        }
        .navigation__views,
        .navigation__bottom {
          padding: 10px 8px;
          a,
          button {
            padding: 0;
            height: 40px;
            border-radius: 4px;
            width: 42px;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after {
              display: none;
            }
            span {
              display: none;
            }
            svg {
              font-size: 19px;
              width: 19px;
            }
            & + a,
            & + button {
              margin-top: 2px;
            }
          }
        }
      }
    }
  }
}
</style>
