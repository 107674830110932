export default {
  settings: {
    views: {
      components: {
        user_settings: {
          title: "Account",
          sub_title: "User Settings",
          email: "Email",
          name: "Name",
          name_placeholder: "First and last name",
          success: "User has beed successfully updated."
        },
        change_password: {
          title: "Change Password",
          sub_title: "Security and authentication",
          password_label: "Current password",
          new_password_label: "New password",
          save: "Change password",
          success: "Your password has been successfully changed!"
        },
        list_range: {
          title: "List Scope",
          sub_title: "Content",
          number_of_items: "Number of items",
          save: "Set Default",
          description1: "Number of items",
          description2: "allows you to set any number of items to be displayed on each page of the lists.",
          success: "Number of items displayed on list was successfully updated"
        }
      }
    }
  }
}
