<template>
    <div id="app" class="uik--restyle">
        <TopBarWithUrl v-if="showTrialBar" :message="showTrialBar.msg" :url="showTrialBar.url" @close="hideTrialBar()" />

        <Authentication @ready="loaded.Authentication = true"/>

        <Navigation v-if="showNavigation && $api.Auth.isAuthenticated" ref="navigation"/>

        <transition :name="transitionName" mode="out-in">
            <router-view v-if="showApp" :key="$route.meta.key ? $route.params[$route.meta.key] : null" @updateNavigationList="$refs.navigation.updateList()" />
        </transition>

        <UpdateAvailable/>

        <Loading :state="loaded.Authentication"/>
    </div>
</template>

<script>
import Authentication from "./Modules/Auth/Components/Authentication"
import UpdateAvailable from "./Components/Support/UpdateAvailable"
import Navigation from "@/Components/Navigation"
import Loading from "@/Components/Loading"
import Auth from "@/Modules/Auth/Auth/Resources/Auth"
import TopBarWithUrl from "@/Components/TopBarWithUrl.vue"

export default {
  components: {
    Authentication,
    Navigation,
    Loading,
    UpdateAvailable,
    TopBarWithUrl
  },
  resources: { Auth },
  getCodelistCategories: ["statuses", "vat_rates", "units", "project_statuses", "units", "classif", "items_set", "document-template-views", "document-template-categories", "probabilities", "weight-unit", "dimension-unit", "claim-status", "currencies"],
  data () {
    return {
      transitionName: "",
      disableNavigationInViews: ["/login", "/not-found", "/registration"],
      loaded: {
        CodelistData: true,
        Authentication: false
      },
      globalSearch: false
    }
  },
  computed: {
    showNavigation () {
      let show = true
      const route = this.$route.path
      this.disableNavigationInViews.forEach(path => {
        if (route.startsWith(path)) show = false
      })
      return show
    },
    showApp () {
      if (this.loaded.Authentication && !this.$api.Auth.isAuthenticated) return true
      return this.loaded.CodelistData
    },
    showTrialBar () {
      // Determine whether message should be shown
      if (this.$api.Auth.isAuthenticated && this.$api.Auth.user) {
        let user = this.$api.Auth.user

        if (this.$api.Auth.showTrialBar) {
          let trialData = user.trial_data
          if (trialData && trialData.trial_expires_at && !trialData.trial_expired) {
            // Set message
            let timeDiffSeconds = this.moment(trialData.trial_expires_at).diff(this.moment(), "seconds")
            let timeDiffDays = timeDiffSeconds / 60 / 60 / 24
            // Show message only 14 days before trial ends
            if (timeDiffDays <= 14) {
              let daysToDisplay = (parseInt(timeDiffDays) + 1).toString()

              let msg = this.$t("Global.trial_bar_messages.expire") + " " + daysToDisplay + " "
              msg = daysToDisplay === "1" ? msg + this.$t("Global.trial_bar_messages.day") + ". " : msg + this.$t("Global.trial_bar_messages.days") + ". "

              msg = msg + this.$t("Global.trial_bar_messages.redirect")

              let obj = { msg: msg }
              obj.url = trialData.url ? trialData.url : null

              return obj
            }
          }
        }
      }

      return false
    }
  },
  watch: {
    "$route" (to, from) {
      // Add fade only transition routes to this array
      const staticViews = ["login", "settings"]
      if (staticViews.includes(this.$route.name)) {
        this.transitionName = "route-fade"
        return
      }
      if (!to.path.split("/").includes(from.path.split("/")[1])) {
        this.transitionName = "route-fade"
        return
      }
      const toDepth = to.path.split("/").length
      const fromDepth = from.path.split("/").length
      if (toDepth < fromDepth) {
        this.transitionName = "slide-right"
      } else if (toDepth > fromDepth) {
        this.transitionName = "slide-left"
      } else {
        this.transitionName = "route-fade"
      }
    }
  },
  methods: {
    hideTrialBar () {
      this.$api.Auth.hideTrialBar()
    }
  }
}
</script>

<style lang="scss">
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.updateAvailable {
  width: 300px;
  position: fixed;
  top: 10px;
  left: 50%;
  margin-left: -150px;
}
@import '@/Styles/index.scss';
</style>
