export default {
  views: {
    list: {
      header: {
        title: "Licence podjetja",
        search_placeholder: "Iskanje ...",
        add_button: "Začni tukaj"
      },
      table: {
        name: "Ime",
        url: "URL do CRMja vašega podjetja",
        created_at: "Ustvarjeno",
        updated_at: "Posodobljeno"
      }
    },
    details: {
      header: {
        back_button: "Seznam licenc podjetja"
      },
      main: {
        active: "Aktiven",
        inactive: "Neaktiven",
        update_tenant: "Posodobite CRM podjetja",
        update_error: "Pri posodabljanju CRMja podjetja je prišlo do napake",
        update_success: "CRM podjetja je bil uspešno posodobljen",
        overview: {
          author: "Avthor",
          created_at: "Ustvarjeno",
          updated_at: "Posodobjeno",
          last_modified: "Nazadnje spremenjeno"
        }
      }
    },
    New: {
      back: "Nazaj",
      next: "Naprej",
      create: "Ustvarite CRM podjetja",
      creating: "Ustvarjanje CRM podjetja",
      save_error: "Pri ustvarjanju CRMja podjetja je prišlo do napake",
      header: {
        back_button: "Seznam licenc podjetja",
        title: "Nova postavitev CRM podjetja",
        licensing_plan_total: "Licenčni načrt skupaj"
      }
    }
  },
  popup: {
    title: "Nov CRM podjetja",
    fields: {
      title: {
        label: "Naslov"
      }
    },
    add_button: "Dodaj CRM podjetja",
    update_button: "Posodobite CRM podjetja"
  },
  prompts: {
    delete: {
      title: "Izbrišite {title}",
      message: "Izbrisali boste {title}. Ali želite nadaljevati s tem dejanjem?",
      cancel: "Prekliči",
      confirm: "Izbriši"
    }
  },
  notify: {
    stored: "CRM podjetja {title} ustvarjen",
    updated: "CRM podjetja {title} posodobljen",
    deleted: "CRM podjetja {title} je bil izbrisan",
    not_found: "CRM določenega podjetja ni mogoče najti"
  },
  Components: {
    Form: {
      Form: {
        title: "Nastavitev aplikacije",
        back_button: "",
        save_button: "Shrani nastavitve",
        save_error: "Med shranjevanjem je prišlo do napake",
        save_success: "Uspešno shranjeno",
        Application: {
          title: "Basic data",
          label: {
            name: "Ime podjetja",
            company: "Podjetje",
            url: "URL aplikacije",
            language: "Jezik",
            email: "Email",
            phone: "Telefon",
            password: "Geslo",
            confirm_password: "Potrdite geslo"
          },
          address: {
            vat_number: "DDV številka",
            title: "Naslov",
            address: "Hišni naslov",
            postal: "Poštna številka",
            city: "Mesto",
            country: "Država",
            phone: "Telefon",
            phone_country_code: "Koda države"
          }
        },
        DataProviders: {
          title: "Ponudnik podatkov",
          description: 'Uvozite podatke podprtih ponudnikov.<br/><a href="https://acronx.com/video-library/#section-17-654" target="_blank">Kako uvoziti podatke</a>',
          error_checking_pantheon_connection: "Med preverjanjem povezave pantheon je prišlo do napake.",
          pantheon_status: {
            untested: "Nepreverjeno",
            valid: "Dela",
            invalid: "Ne dela",
            testing: "Testiranje..."
          },
          label: {
            selected_provider: "Izberite ponudnika podatkov",
            host: "Naslov gostitelja",
            port: "Port",
            database: "Ime baze podatkov",
            username: "Uporabniško ime baze podatkov",
            password: "Geslo baze podatkov",
            connection_test: "Povezava z bazo podatkov"
          },
          test_connection_button: "Preveri povezavo"
        },
        Plugins: {
          title: "Vtičniki",
          description: "Trenutno podprti ponudniki. Več kmalu.",
          link: "Link",
          registration: "Registracija",
          newsletter: {
            title: "Glasilo",
            description: 'Ponudnik glasila bo zagotovil podporo pri pošiljanju novic.<br/><a href="https://acronx.com/video-library/#section-379-654" target="_blank">How to set up newsletter support</a>',
            selected_provider: "Izberite ponudnika podatkov",
            squalomail: {
              description: "SqualoMail je platforma za e-poštni marketing.",
              link: "https://www.squalomail.com/sl/",
              registration: "https://register.squalomail.com/free-trial/sl/",
              key: "API KEY"
            }
          },
          business_data: {
            title: "Poslovni podatki",
            description: 'Ponudnik poslovnih podatkov bo samodejno izpolnil podatke za poslovne stranke.<br/><a href="https://acronx.com/video-library/#section-381-654" target="_blank">Kako nastaviti samodejno izpolnjevanje podatkov za poslovne stranke</a>',
            selected_provider: "Izberite ponudnika podatkov",
            bisnode: {
              description: "Bisnode je ponudnik podatkov o lokalnih podjetjih.",
              link: "https://bisnode.si/",
              registration: "https://accounts.bisnode.si/Authenticate/?product=0&language=en-US&returnUrl=https%3A%2F%2Faccounts.bisnode.si%2FHome%2F%3Fproduct%3D0%26language%3Dsl-SI%26basLanguage%3Den-US",
              username: "Uporabniško ime",
              password: "Geslo"
            }
          },
          mailbox: {
            title: "Poštni nabiralnik",
            description: "Nabiralnik bo dohodno e-pošto povezal z obstoječimi entitetami.",
            host: "Gostitelj",
            port: "Port (SSL IMAP)",
            username: "Uporabniško ime",
            password: "Geslo"
          }
        },
        Subscription: {
          licences_plan: "Naročniški načrt",
          licences_plan_description: 'Izberite svoj naročniški paket. Odločate se lahko med mesečno in letno možnostjo. Ob letni naročnini nudimo popust.<br/><a href="https://acronx.com/video-library/#section-387-654" target="_blank">Kako izbrati naročnino</a>',
          free_trial_card_title: "Brezplačen preizkus",
          free_trial_card_description: "Izberite to možnost, če želite začeti neomejeno brezplačno 40-dnevno preskusno obdobje!",
          paid_licensing_plan_card_title: "Plačan licenčni načrt",
          paid_licensing_plan_card_description: "Izberite to možnost, za plačljivo naročnino",
          title: "Licenčni načrt",
          monthly: "Mesečno",
          yearly: "Letno",
          licences: "Licence",
          licences_description: "Osnovna licenca, ki jo potrebuje vsak uporabnik. Izberite vsaj enega.",
          modules: "Moduli",
          modules_description: "Licence za module, ki omogočajo vklop modulov za posamezne uporabnike. (Optional)",
          assets: "Sredstva",
          assets_description: "Tukaj kupite sredstva, ki jih lahko uporabljajo vsi uporabniki. (Optional)",
          total_title: "Licenčni načrt skupaj:",
          free_for_days: "* za prvih 40 dni",
          calculating_differences: "Izračunavanje razlik v plačilu naročnine, počakajte.",
          total_subscription: "Skupna cena naročnine:",
          change_from_previous: "Sprememba od prejšnjega:",
          current_balance: "Vaše trenutno dobroimetje:",
          balance_after_change: "Vaše dobroimetje po posodobitvi naročnine:",
          update_to_pay: "Znesek plačila po posodobitvi naročnine:",
          change_tooltip: "Negativne številke predstavljajo znesek, ki bo dodan dobroimetju vašega Stripe računa in porabljen pri naslednjem nakupu.",
          balance_tooltip: "Če želite prejeti denar nazaj, se obrnite na skrbnika.",
          total_tooltip: "To je znesek, ki ga boste plačali v naslednjem obračunskem obdobju.",

          Components: {
            PlanCard: {
              used: "{used} v uporabi",
              info: "Števila licenc ne morete zmanjšati pod število uporabljenih."
            }
          },

          proration_preview_error: "Prišlo je do napake. Preverite vpisane podatke, še posebej državo in DDV številko."
        },
        Payment: {
          title: "Plačilo",
          subtitle: "Izberite ali ustvarite način plačila",
          trial_describtion: "Izbrali ste brezplačno preskusno obdobje.<br><br>Postopek namestitve lahko zaključite s klikom na \"Create tenant\" gumb spodaj.",
          Components: {
            StripePaymentForm: {
              pay_now: "Plačaj"
            },
            PaymentMethods: {
              add_payment_method: "Dodajte način plačila",
              Components: {
                AddPaymentMethodForm: {
                  title: "Dodajte način plačila",
                  add_button: "Dodaj način plačila",
                  cardholder_name: "Ime imetnika kartice"
                }
              }
            }
          }
        }
      }
    }
  }
}
