// import router from "@/providers/router"
import RequestAction from "@/providers/api/sourceDrivers/webApiDriver/actionTypes/requestAction"
import router from "@/providers/router"
import GetFiltersRequestParamsJob from "../../Filter/jobs/getFiltersRequestParams"
import { saveAs } from "file-saver"

export default class ExportListAction extends RequestAction {
  /**
   * Exports the list of items from api
   *
   * @param {object} params - Filtering, sorting and pagination parameters
   *
   * @returns {object} Response status and data
   */
  async handle ({ state }, params = {}) {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      },
      responseType: "blob"
    }
    let res = await this.request({ namespace: "exportList" }).post("export/" + state.route, { params: {
      // Load default params
      ...state.listDefaultParams,
      // Load sort param
      sort: state.listSortParam,
      // Load filters
      filter: new GetFiltersRequestParamsJob().run(state, router.currentRoute.name, params && params.filter ? params.filter : {}),
      // Load other request specific params
      ...params
    } }, config)
    return saveAs(res.data, state.route + ".xlsx")
  }
}
